import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../components/Layout';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const OurStory = () => {
  return (
    <Layout fullMenu>
      <article id="main">
        {/* <header style={{ height: 200 }}>
        </header> */}
        <section className="wrapper style5">
          <h2 style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
            Extraordinarily Independent
          </h2>
          <div className="inner">
            <p>
              Welcome to Agency Insurance, where your protection is our
              priority. Our journey began with a vision to provide a different
              kind of insurance experience – one that revolves around you, your
              unique needs, and your peace of mind. Our core belief is that
              independence empowers us to serve you better. We are an
              independent insurance brokerage, which means we are not tied to a
              single insurance company. Instead, we represent a variety of
              reputable insurance providers. This independence allows us to
              customize policies tailored to your specific needs.
            </p>
            <h4>Customer-Centric Philosophy</h4>
            <p>
              Our philosophy is centered on you and your convenience. We
              understand that insurance isn't just about paperwork and policies;
              it's about protecting what matters most to you – your family, your
              assets, your dreams. Our dedicated team of insurance experts takes
              the time to get to know you, your story, and your aspirations. We
              believe in building lasting relationships because your trust is
              the foundation of our success.
            </p>
            <h4>Local Roots, National Expertise</h4>
            <p>
              With local roots in your community being a small privately owned
              agency, we're well-versed in the unique insurance needs and
              challenges you face. Whether you're a homeowner, a business owner,
              a traveler, or a dreamer, we have the knowledge and expertise to
              safeguard your interests. We leverage our extensive network of
              insurance providers to ensure you have access to the best options
              in the market.
            </p>
            <h4>Simplifying the Complex</h4>
            <p>
              Insurance can be complex and intimidating. We're here to simplify
              it for you. Our commitment is to provide you with clear,
              jargon-free explanations and guidance. We'll walk you through the
              intricate world of insurance, making sure you understand your
              options, so you can make informed decisions.
            </p>
            <h4>Your Advocate in Every Situation</h4>
            <p>
              From finding the right coverage to assisting with claims, we're
              your dedicated advocate at every step. When life takes an
              unexpected turn, you can count on us to support and guide you.
              We'll work tirelessly to ensure your interests are protected.
            </p>
            <h4>The Future of Insurance</h4>
            <p>
              As the insurance landscape evolves, we evolve with it. We embrace
              cutting-edge technology to streamline the insurance process, but
              our commitment to personal service remains unwavering. We are
              dedicated to being your trusted partner, both today and in the
              future.
            </p>
            <h4>Join Us on Your Journey</h4>
            <p>
              Your journey is our journey. At Agency Insurance, we're not just
              in the business of providing insurance; we're in the business of
              safeguarding your dreams, ambitions, and the well-being of your
              loved ones. We invite you to join us on this journey, to
              experience the difference of an independent insurance brokerage
              that truly cares about you.
              <br />
              <br />
              Thank you for considering Agency Insurance as your partner in
              protection. We look forward to serving you, understanding your
              story, and helping you write the next chapter.
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default OurStory;
